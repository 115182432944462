import { Link as GatsbyLink } from "gatsby"
import React from "react"

// Since DOM elements <a> cannot receive activeClassName,
// destructure the prop here and pass it only to GatsbyLink
const StoryblokLink = props => {
  const { storyblokLink, children, activeClassName, target, ...other } = props

  if (storyblokLink.linktype === "story") {
    return (
      <GatsbyLink
        to={`/${
          storyblokLink.cached_url === "home" ? "" : storyblokLink.cached_url
        }`}
        activeClassName={activeClassName}
        {...other}
      >
        {children}
      </GatsbyLink>
    )
  }
  return target === "_blank" ? (
    <a
      href={storyblokLink.url}
      target="_blank"
      rel="noopener noreferrer"
      {...other}
    >
      {children}
    </a>
  ) : (
    <a href={storyblokLink.url} {...other}>
      {children}
    </a>
  )
}

export default StoryblokLink
