// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-editor-js": () => import("./../src/pages/editor.js" /* webpackChunkName: "component---src-pages-editor-js" */),
  "component---src-pages-projects-index-js": () => import("./../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-tayama-index-js": () => import("./../src/pages/projects/tayama/index.js" /* webpackChunkName: "component---src-pages-projects-tayama-index-js" */),
  "component---src-pages-projects-tayama-rysunki-techniczne-js": () => import("./../src/pages/projects/tayama/rysunki-techniczne.js" /* webpackChunkName: "component---src-pages-projects-tayama-rysunki-techniczne-js" */),
  "component---src-templates-storyblok-entry-js": () => import("./../src/templates/storyblok-entry.js" /* webpackChunkName: "component---src-templates-storyblok-entry-js" */)
}

