import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Clock from "react-live-clock"
import { format } from "date-fns"
import { pl } from "date-fns/locale"
import { Heading, Flex } from "@chakra-ui/core"

import { auth } from "../services/auth"
import Header from "../components/Header"
import Footer from "../components/Footer/Footer"
import SettingsContext from "../helpers/settingsContext"

const Layout = props => {
  useEffect(() => {
    const confirmToken = (auth, authInfo) => {
      auth
        .confirm(authInfo.token)
        .then(function(response) {
          console.log(
            "Account confirmed!Welcome to the party!",
            JSON.stringify({ response })
          )
        })
        .catch(function(e) {
          console.error(e)
        })
    }

    const currentLocation = props.children.props.location
    const hash = currentLocation.hash
    let netlifyIdentityHash
    if (hash.split("#").length > 1) {
      netlifyIdentityHash = {
        name: hash.split("#")[1].split("=")[0],
        token: hash.split("#")[1].split("=")[1],
      }

      if (netlifyIdentityHash.name === "confirmation_token") {
        confirmToken(auth, netlifyIdentityHash)
      }
    }
  }, [])

  const staticData = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const newTodaysDate = new Date()
  const today = `${newTodaysDate.getDate()}.${newTodaysDate.getMonth() +
    1}.${newTodaysDate.getFullYear()}`

  return (
    <SettingsContext.Consumer>
      {({ settings }) => (
        <>
          <Header
            siteTitle={staticData && staticData.site.siteMetadata.title}
            settings={settings}
            pageContext={props.pageContext && props.pageContext}
          />
          <Flex direction="column">
            <Heading as="h2" size="xs" margin="24px auto">
              Dzisiaj jest:{" "}
              <span style={{ color: "gray" }}>
                {format(new Date(), "iiii", { locale: pl })} {today}{" "}
                <Clock
                  format={"HH:mm:ss"}
                  ticking={true}
                  timezone={"Europe/Warsaw"}
                />
              </span>
            </Heading>
            {/* <div> */}
            <main className="body">{props.children}</main>
            {/* </div> */}
          </Flex>
          <Footer />
        </>
      )}
    </SettingsContext.Consumer>
  )
}

export default Layout
