import GoTrue from "gotrue-js"
import { navigate } from "gatsby"

export const auth = new GoTrue({
  APIUrl: "https://cc.codepride.pl/.netlify/identity",
  audience: "",
  setCookie: false,
})

export const isBrowser = () => typeof window !== "undefined"

export const getJWTToken = () =>
  isBrowser() && window.localStorage.getItem("jwt")
    ? JSON.parse(window.localStorage.getItem("jwt"))
    : null

const setJWTToken = user =>
  window.localStorage.setItem("jwt", JSON.stringify(user))

export const handleLogin = ({ email, password }) => {
  return auth
    .login(email, password)
    .then(response => {
      console.log("Success!")
      setJWTToken(response.token.access_token)
      navigate(`/app/`)
      return { response, status: "ok" }
    })
    .catch(error => {
      return { error, status: "error" }
    })
}

export const isLoggedIn = () => {
  const jwt = getJWTToken()

  return !!jwt
}

export const logout = callback => {
  setJWTToken(null)
  callback()
}
