import React, { useState } from "react"
import { Box, Flex, Text } from "@chakra-ui/core"
import { Link as GatsbyLink, navigate } from "gatsby"
import StoryblokLink from "../StoryblokLink/StoryblokLink"
import { isLoggedIn, logout } from "../../services/auth"

const MenuItem = ({ children }) => (
  <Text mt={{ base: 4, md: 0 }} mr={6} display="block">
    {children}
  </Text>
)

const Header = props => {
  const [show, setShow] = useState(false)
  const { siteTitle, settings } = props
  const handleToggle = () => setShow(!show)

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding="1.5rem"
      bg="teal.500"
      color="white"
      {...props}
    >
      <Flex align="center" mr={5}>
        <GatsbyLink
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </GatsbyLink>
      </Flex>

      <Box display={{ sm: "block", md: "none" }} onClick={handleToggle}>
        <svg
          fill="white"
          width="12px"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </Box>

      <Box
        display={{ sm: show ? "block" : "none", md: "flex" }}
        width={{ sm: "full", md: "auto" }}
        alignItems="center"
        flexGrow={1}
      >
        {settings &&
          settings.content &&
          settings.content.main_nav.map(item => {
            return (
              <MenuItem key={item._uid}>
                {item.link.linktype === "url" && item.link.url[0] === "/" ? (
                  item.link.url.split("/")[1] === "app" ? (
                    item.link.url === "/app/login" && isLoggedIn() ? (
                      <a
                        href="/"
                        onClick={event => {
                          event.preventDefault()
                          logout(() => navigate(`/app/login`))
                        }}
                      >
                        Logout
                      </a>
                    ) : isLoggedIn() &&
                      item.link.url.split("/")[2] === "protected" ? (
                      <GatsbyLink to={item.link.url} style={{ color: "#fff" }}>
                        {item.name}
                      </GatsbyLink>
                    ) : (
                      <GatsbyLink to={item.link.url} style={{ color: "#fff" }}>
                        {item.name}
                      </GatsbyLink>
                    )
                  ) : (
                    <GatsbyLink to={item.link.url} style={{ color: "#fff" }}>
                      {item.name}
                    </GatsbyLink>
                  )
                ) : (
                  <StoryblokLink
                    storyblokLink={item && item.link && item.link}
                    style={{ color: "#afafaf" }}
                  >
                    {item.name}
                  </StoryblokLink>
                )}
              </MenuItem>
            )
          })}
      </Box>
    </Flex>
  )
}

export default Header
