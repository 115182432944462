import React from "react"
import { ThemeProvider } from "@chakra-ui/core"
import { customTheme } from "../styles/theme"
import Layout from "./layout"
import BlankLayout from "./blankLayout"
import { SettingsContextProvider } from "../helpers/settingsContext"
import { CSSReset } from "@chakra-ui/core"
import "notyf/notyf.min.css"
import "../styles/main.scss"

const PageWrapper = ({ children, settings, theme }) => (
  <ThemeProvider theme={theme}>
    <CSSReset />
    <SettingsContextProvider settings={settings}>
      {children}
    </SettingsContextProvider>
  </ThemeProvider>
)

export default ({ children, pageContext }) => {
  const settings = Object.assign({}, pageContext.settings)
  if (typeof settings.content === "string") {
    settings.content = JSON.parse(settings.content)
  }

  if (pageContext.layout === "blank") {
    return (
      <PageWrapper settings={settings} theme={customTheme}>
        <BlankLayout pageContext={pageContext} settings={settings}>
          {children}
        </BlankLayout>
      </PageWrapper>
    )
  }

  return (
    <PageWrapper settings={settings} theme={customTheme}>
      <Layout pageContext={pageContext} settings={settings}>
        {children}
      </Layout>
    </PageWrapper>
  )
}
